import dato from "./dato";
import rol_dato from "./rol_dato";
import usuario_dato from "./usuario_dato";

import ruta from "./ruta"
import vehiculo from "./vehiculo"
import tipo_vehiculo from "./tipo_vehiculo"
import enviroments from "./enviroments";
export default {
    dato,
    rol_dato,
    usuario_dato,
    ruta,
    vehiculo,
    tipo_vehiculo,
    enviroments
}