export default {
    debug: true, //para que se vea los returns del server
    name: 't',

    ssl: true,
    host: 'tllebo.servisofts.com',

    // ssl: false,
    // host: '10.83.253.250',    

    // ssl: false,
    // host: '192.168.2.1',
    port: {
        native: 10050,
        web: 20050,
        http: 30050
    },
    // cert: 'MIID0DCCArigAwIBAgIEYgRJTTANBgkqhkiG9w0BAQsFADCBqTELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEXMBUGA1UECgwOU2Vydmlzb2Z0cyBTUkwxDzANBgNVBAsMBnRhcGVrZTEeMBwGA1UEAwwVdGFwZWtlLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhyaWNreS5wYXouZC45N0BnbWFpbC5jb20wHhcNMjIwMjA5MjMwNzU3WhcNMjIwMjEwMjMwNzU3WjCBqTELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEXMBUGA1UECgwOU2Vydmlzb2Z0cyBTUkwxDzANBgNVBAsMBnRhcGVrZTEeMBwGA1UEAwwVdGFwZWtlLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhyaWNreS5wYXouZC45N0BnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCaIRwLARQqohcmnm44B3Qca60sKgo/H6tXeQ7tQyvrMAmNZ2mcod0xDpUFnyEu9Ua+Bb9kF1PCPB0udqLmKG5ZeXoHkSRNF/yM++IxzjUDjNw13hyh/2cfi7GvEC2gUer5TBl0ZJWVGGcXUywlFo5DtH2uAbO8QKnnpqYEqAH9fbiP9P7TqbpkbEcMz8bgsDc8+RJB5nqcc/jdUCRA9n6a1ey3ncH26qtInsp/m0reF+MTJBzMJbYU9erUAP/GKcwmy4NE2UiFwu0QDF6AjuaI3m3FJ5Gp2z59aJeaXdHAx2SiB0wsZuw6Xun29RcGZ2KQk6iIkKg+xFU9E7AnI33BAgMBAAEwDQYJKoZIhvcNAQELBQADggEBAGASGpT2VF+qrPj8cRwMIttnsxT/eOKWjG6gL3RewnBen7bTmAg4qjZ4TYLte6aLbbu6V/WDh52WN5rarLj3/KOHfcKmsKHpjfCuapcGEisRvB9Hpx9sePqxsEm0wjjpyfArX+Yd2E6/hFeeDnWitjgtMe5NvvSDdnVOaUliZPzbMlUT+SB60zZC00PhNCJIr+uKdApVRBabMUMNlZoM5O2fiJ7KlpKnkZWh4Xsgs2cb97pbOYfXo5NU9C+F2tjoAOkBUZVXduKTnYeojvWgyK1YNDzOeX4F3gAXpwkXdH38NtuIpSKDsMmfWRk+YU931YYAck6aBhTSEGvRe3OdQHM=',
    cert: 'MIID6jCCAtKgAwIBAgIEZQpTzDANBgkqhkiG9w0BAQsFADCBtjELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEaMBgGA1UECgwRU0VSVklTT0ZUUyBTLlIuTC4xFDASBgNVBAsMC3N0cmFuc3BvcnRlMSMwIQYDVQQDDBpzdHJhbnNwb3J0ZS5zZXJ2aXNvZnRzLmNvbTEnMCUGCSqGSIb3DQEJARYYc2Vydmlzb2Z0cy5zcmxAZ21haWwuY29tMB4XDTIzMDkyMDAyMDcwOFoXDTIzMDkyMTAyMDcwOFowgbYxCzAJBgNVBAYTAkJPMRIwEAYDVQQIDAlBdiBCYW56ZXIxEzARBgNVBAcMClNhbnRhIENydXoxGjAYBgNVBAoMEVNFUlZJU09GVFMgUy5SLkwuMRQwEgYDVQQLDAtzdHJhbnNwb3J0ZTEjMCEGA1UEAwwac3RyYW5zcG9ydGUuc2Vydmlzb2Z0cy5jb20xJzAlBgkqhkiG9w0BCQEWGHNlcnZpc29mdHMuc3JsQGdtYWlsLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJbIkdccy4ATfdJjNKrUb+aoP0VS4MxG8TWeysqv8R5whI6v2lzYRc1bNsVI3kUXozDuBJ7mk0z6LZMZ/OuCq2GkO2kCj1YQSoe2n6Mfwh6aIL9Tovsr1dWZccRr1RKlTO49IMOgMKtsxaJ0kg863nfiOSf2bUiAgIQUuK0bNE/jtUTWJdfuoooEEqlnyj9kHte9uXV8/2oot9ChffDpuJZuY5XQcTKjF5wkFFfdSB5H/WIEaoJA5pkM5cHNQ76fEr6JUPcwSyxidipqJ8MjZBcTUq4v34ZIj3WJwWO7HY7LTV1RrV2dO5FYP5mN9sjYmNaOYZSZRLelfuJIu53fZisCAwEAATANBgkqhkiG9w0BAQsFAAOCAQEAkWD7nMSvzSPiltmk8X0Yqp2WDswX3RWJCkNIwMu1UjY7skOJa6NlEBH+tqTp4dX/2AZ7BQIExEUE1YbVO4dO40IoB8WQEj1yfwdInaJsoVKtnx1sEd18S0hQwud8UzARKC4jbEq9sENrmy/opy9ryqbtlmFKHDki31QejcUlPlFjRBNWhrZLkfR46EZ9doIbJg1rCFeO6gJJoz46iNPqihUvGhxlRRiCG0yTBYd7Ij1LNPd2AwEAEWjsw6M2r1N+m5TCLHutaIGMbSmNqj4jcabGpw0fk8Y+owjPSOz0PuvoIdq8FOwvdAvVU23n7QOtx6pDNDvtYiiEBiEUutTYPQ==',
    apis: {
        roles_permisos: 'https://rolespermisos.servisofts.com/http/',
        // repo: "https://tllebo.servisofts.com/repo/transporte/",
        repo: "https://tllebo.servisofts.com/images/",
        // repo: "http://192.168.2.1:30050/",
    },
    timeReconnect: 5000
}