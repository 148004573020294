export const SQL = {
    "\\bselect\\b": "#ff0000",
    "\\bfrom\\b": "#ff0000",
    "\\bwhere\\b": "#ff0000",
    "\\bleft\\b": "#ff0000",
    "\\bon\\b": "#ff0000",
    "\\bbetween\\b": "#ff0000",
    "\\band\\b": "#ff0000",
    "\\bsum\\b": "#ff0000",
    "\\bcount\\b": "#ff0000",
    "\\bas\\b": "#ff0000",
    "\\btop\\b": "#ff0000",
    "\\bgroup\\b": "#ff0000",
    "\\border\\b": "#ff0000",
    "\\bby\\b": "#ff0000",
    "\\bdesc\\b": "#ff0000",
    "\\basc\\b": "#ff0000",
    "'.*?'": "#CE9178",
    "--.*": "#699754"
}
