import Card from "./Card"
import CardPerfil from "./CardPerfil"
import BoxMenuLat from "./BoxMenuLat"
import BoxMenuLatOtros from "./BoxMenuLatOtros"
import CardLike from "./CardLike"
import CardPost from "./CardPost"
export default {
    Card,
    CardPerfil,
    BoxMenuLat,
    BoxMenuLatOtros,
    CardLike,
    CardPost
    
}