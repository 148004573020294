import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import DeviceKey from "./DeviceKey";
import { SNotification } from "servisofts-component";
// export { DeviceKey };
class Firebase {
    static async getInitialURL() {

    }
    static async init() {
        // DeviceKey.init();
        try {

            const firebaseConfig = {
                apiKey: "AIzaSyDIGGo5k9UOhXJsx0Cn_QkWgCWMxGR3V1s",
                authDomain: "tllebo.firebaseapp.com",
                projectId: "tllebo",
                storageBucket: "tllebo.appspot.com",
                messagingSenderId: "357431125754",
                appId: "1:357431125754:web:631ca95d47b0337a668b43",
                measurementId: "G-34EYHH97ZP"
            };
            const app = initializeApp(firebaseConfig)
            const messaging = getMessaging(app);
            getToken(messaging, { vapidKey: "BNYUaJwLDiXwr3ibM4h8ojktYaQDdNX50CaO4j65ldMkds3yIZRE92TanDkBNekVigoqG3gsdQWjQBE5VjPGoo8" }).then((currentToken) => {
                if (currentToken) {
                    console.log('Token: ', currentToken);
                    DeviceKey.setKey(currentToken);
                    // Send the token to your server and update the UI if necessary
                    // ...
                    onMessage(messaging, (payload) => {
                        console.log('Message received. ', payload);
                        Notification.requestPermission().then(function (result) {
                            var notification = new Notification(payload.notification.title, { body: payload.notification.body });
                        });
                        SNotification.send(payload.notification)
                        // ...
                    });
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        } catch (err) {
            console.log("error", err);
        }

    }
}
export default Firebase;