import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { STheme, SView } from 'servisofts-component'

export default class MenuBar extends Component {
    render() {
        return <SView col={"xs-12"} height={30} backgroundColor={STheme.color.barColor} >

        </SView>
    }
}